import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/return.scss";
import returnImg from "../image/returns-img.png";

class Returns extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="return-page">
          <div className="title">Free Returns</div>
          <div className="desc-content">
            <div className="delivery-img">
              <img src={returnImg} alt="" />
            </div>
            <div className="title">Send it back - no questions asked</div>
            <p>
              If you’re not totally thrilled with your new Omlet products,
              please tell us so we can try and put things right. To this end,
              please hold onto the original packaging until you’re 100%
              satisfied. Provided it’s within 180 days, we’ll always take it
              back.
            </p>
            <p>
              Products listed on the Outlet page are not covered by our free
              returns policy. You will need to pay for shipping if you'd like to
              return an Outlet product.
            </p>
          </div>
          <div className="desc-content">
            <div className="title">How, though?</div>
            <p>
              Just send it back to us with your details. If your pet’s used it,
              please give it a clean first!
            </p>
            <p>
              Drop us a line on 646-434-1104 (Mon to Fri, 7am - 5pm EST) or at
              {/* email */}
              info@omlet.us and we’ll give you a return label for drop off at a
              FedEx shop. Once we have your parcel, we’ll check and process it
              and issue your exchange or refund within 14 days.
            </p>
            <p>Outlet items</p>
            <p>
              If you are returning a product that you bought from our Outlet
              page, please contact us by telephone on
              {/* phone */}
              646-434-1104 (Mon to Fri, 7am - 5pm EST) or by email
              {/* email */}
              info@omlet.us so that we can provide you with the returns address.
              Please note that you will need to pay for shipping if you would
              like to return an Outlet product.
            </p>
          </div>
          <div className="desc-content">
            <div className="title">The small print</div>
            <p>
              All delivery options are subject to availability and working days
              exclude public holidays. Please note that all delivery times are
              provided as estimates and occasionally deliveries will occur
              either earlier or later than expected. If you live in a
              particularly rural or offshore location it is highly likely that
              your delivery will take longer than the normal delivery time.
            </p>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Returns;
