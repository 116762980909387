import React, { Component } from "react";
import "../style/productDetail.scss";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import { getProductDetailById, getRandomElements } from "../apis/GetProduct";
import Truck from "../icon/Truck";
import Time from "../icon/Time";
import Number from "./common/Number";

class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: {},
      isActive: 0,
      randomList: [],
    };
    this.clickChangeImage = this.clickChangeImage.bind(this);
    this.clickAddToCart = this.clickAddToCart.bind(this);
    this.routePage = this.routePage.bind(this);
  }
  headerRef = React.createRef();
  numberRef = React.createRef();

  componentDidMount() {
    window.scrollTo(0, 0);
    let { location } = this.props;
    let id = location.pathname.replace("/ProductDetail/", "");
    let product = getProductDetailById(parseInt(id));
    this.setState({ product });
  }

  clickChangeImage(index) {
    this.setState({ isActive: index });
  }

  clickAddToCart(product) {
    let isProductInCart = false;
    let quantity = 0;
    let num = 0;
    // if (this.numberRef) {
    //   product.quantity = this.numberRef.current.state.num;
    // }

    if (this.headerRef) {
      let cartList = this.headerRef.current.state.cartList;

      if (cartList.length <= 0) {
        cartList.push(product);
      } else {
        for (let i = 0; i < cartList.length; i++) {
          if (cartList[i].id === product.id) {
            cartList[i].quantity += product.quantity;
            cartList[i].total = (
              cartList[i].quantity * parseFloat(product.price)
            ).toFixed(2);
            isProductInCart = true;
            break;
          }
        }

        if (!isProductInCart) {
          cartList.push(product);
        }
      }

      cartList.forEach((item) => {
        quantity += item.quantity;
      });

      this.headerRef.current.setState({ cartList, quantity });
      localStorage.setItem("cart", JSON.stringify(cartList));
      localStorage.setItem("quantity", JSON.stringify(quantity));
      this.routePage("cart");
    }
  }

  hoverChange(index) {
    let { randomList } = this.state;
    randomList[index].isHover = !randomList[index].isHover;
    this.setState({ randomList });
  }

  routeProductDetail(id) {
    let { history } = this.props;
    history.push(`/ProductDetail/${id}`);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "about":
        history.push("/About");
        break;
      case "pricing":
        history.push("/Pricing");
        break;
      case "contact":
        history.push("/Contact");
        break;
      case "privacy":
        history.push("/Privacy");
        break;
      case "cart":
        history.push("/Cart");
        break;
      default:
        break;
    }
  }

  render() {
    let { product, isActive } = this.state;

    return (
      <React.Fragment>
        <HeaderBar {...this.props} ref={this.headerRef} />
        <div className="productDetail-page">
          <div className="productDetail">
            <div className="row1">
              <div className="left-box">
                <div className="big-img">
                  <img src={product.image && product.image[isActive]} alt="" />
                </div>
              </div>
              <div className="right-box">
                <div className="title">{product.title}</div>
                {product.sku &&
                  product.sku.map((item, index) => {
                    return (
                      <div className="sku" key={index}>
                        <div className="sku-title">{item.title}</div>
                        <div className="sku-value">{item.value}</div>
                      </div>
                    );
                  })}

                <div className="btn-row">
                  <div className="price">In Stock ${product.price}</div>
                  <div
                    className="addToCart"
                    onClick={() => this.clickAddToCart(product)}
                  >
                    ADD TO CART
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row2">
            {product.description &&
              product.description.map((item, index) => {
                return index % 2 == 0 ? (
                  <div className="left-img">
                    <div className="col">
                      <div className="title">{item.title}</div>
                      {item.description.map((item) => {
                        return <p className="desc">{item}</p>;
                      })}
                    </div>
                    <div className="col">
                      <div className="img-content">
                        <img src={item.img} alt="" />
                        <div className="text">{item.imgText}</div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="left-img" key={index}>
                    <div className="col">
                      <div className="img-content">
                        <img src={item.img} alt="" />
                        <div className="text">{item.imgText}</div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="title">{item.title}</div>
                      {item.description.map((item) => {
                        return <p className="desc">{item}</p>;
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default ProductDetail;
