import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/delivery.scss";
import deliveryImg from "../image/delivery.png";

class Delivery extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="delivery-page">
          <div className="title">Delivery</div>
          <div className="desc-content">
            <div className="delivery-img">
                <img src={deliveryImg} alt="" />
            </div>
            <div className="title">Free as a bird shipping</div>
            <p>
              Spend over $100 and it’s free shipping all the way, applied
              automatically at checkout.
            </p>
            <p>
              At this point, we’re unable to offer free shipping to Alaska,
              Hawaii, Puerto Rico, the Virgin Islands, any US overseas
              territories or P.O. boxes.
            </p>
          </div>
          <div className="desc-content">
            <div className="title">What’s the wait?</div>
            <p>
              If your product is in stock, we aim to dispatch it within 48
              hours, but please wait for your shipping confirmation email to
              track your package.
            </p>
            <p>
              Out of stock products have an estimated delivery time next to the
              Add to cart button.
            </p>
            <p>
              We do our utmost to get your order to you ASAP, but the best laid
              plans occasionally go awry. Contact our dedicated customer service
              team on
              {/* email */}
              info@omlet.us , stating your order number, with any delivery
              issues and we’ll be on the case.
            </p>
          </div>
          <div className="desc-content">
            <div className="title">Where to?</div>
            <p>We ship using a FedEx or UPS ground service.</p>
            <p>
              If you’re in Canada or New Zealand, please order on our UK
              website: www.omlet.co.uk
            </p>
          </div>
          <div className="desc-content">
            <div className="title">Duty payments</div>
            <p>
              Products delivered to some destinations may be subject to taxes,
              fees or other charges imposed by local legislation. The recipient
              of the products is responsible for all customs formalities for
              export and import, and will be required to pay any additional
              charges levied. The recipient, not the person placing the order,
              will receive a separate request for payment of these charges.
            </p>
            <p>
              If you're ordering products to be delivered elsewhere, please
              ensure the recipient is aware of any additional charges that may
              apply. Unfortunately, we have no control over these charges and
              are unable to offer any assistance in this area. We advise
              checking the import charges applicable in any country before
              ordering products to be delivered there.
            </p>
          </div>
          <div className="desc-content">
            <div className="title">The small print</div>
            <p>
              All delivery options are subject to availability and exclude
              public holidays. All delivery times are given as estimates. If
              you’re in a particularly rural or an offshore location, your
              delivery is likely to take longer than the time stated.
            </p>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Delivery;
