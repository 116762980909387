import React, { Component } from "react";
import "../../style/common/headerBar.scss";
import ShoppingCart from "../../icon/ShoppingCart";
import logo from "../../image/omlet-logo.svg";
import shoppingReturn from "../../image/returns.png";
import off from "../../image/off.png";
import days from "../../image/180-day.png";

class HeaderBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartList: [],
      quantity: 0,
    };
    this.routePage = this.routePage.bind(this);
  }
  componentDidMount() {
    if (localStorage.getItem("cart")) {
      let cartList = JSON.parse(localStorage.getItem("cart"));
      let quantity = 0;
      cartList.map((item) => {
        quantity += item.quantity;
      });
      this.setState({ cartList, quantity });
    }
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "about":
        history.push("/About");
        break;
      case "pricing":
        history.push("/Pricing");
        break;
      case "contact":
        history.push("/Contact");
        break;
      case "privacy":
        history.push("/Privacy");
        break;
      case "cart":
        history.push("/Cart");
        break;
      default:
        break;
    }
  }

  render() {
    const { quantity } = this.state;
    return (
      <React.Fragment>
        <div className="top-head">
          <div className="li">
            <img src={shoppingReturn} alt="" />
           <div className="p"> FREE shipping on orders over $100</div>
          </div>
          <div className="li">
            <img src={off} alt="" />
            <div className="p">10% off your first order</div>
          </div>
          <div className="li">
            <img src={days} alt="" />
            <div className="p">180-day guarantee</div>
          </div>
        </div>
        <div className="header">
          <div className="left">
            <div className="logo" onClick={() => this.routePage("home")}>
              <img src={logo} alt="" />
            </div>
            <div className="tab">
              <div className="li" onClick={() => this.routePage("about")}>
                About us
              </div>
              <div className="li" onClick={() => this.routePage("contact")}>
                Contact us
              </div>
            </div>
          </div>
          <div className="shop" onClick={() => this.routePage("cart")}>
            <ShoppingCart size={35} color="#1f583c" />
            <div className="num">{quantity}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default HeaderBar;
