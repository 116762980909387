import React, { Component } from "react";
import "../../style/common/footerBar.scss";
import Facebook from "../../icon/Facebook";
import Instagram from "../../icon/Instagram";
import Pinterest from "../../icon/Pinterest";

import TikTok from "../../icon/TikTok";
import logo from "../../image/omlet-logo.svg";
import footImg from "../../image/footer.png";
import facebook from "../../image/facebook.svg";
import twitter from "../../image/twitter.svg";
import instagram from "../../image/instagram.svg";
import pinterest from "../../image/pinterest.svg";
import youtube from "../../image/youtube.svg";

class FooterBar extends Component {
  constructor(props) {
    super(props);
    this.routePage = this.routePage.bind(this);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "privacy":
        history.push("/Privacy");
        break;
      case "about":
        history.push("/About");
        break;
      case "contact":
        history.push("/Contact");
        break;
      case "delivery":
        history.push("/Delivery");
        break;
      case "return":
        history.push("/Returns");
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="footerBar">
          <div className="foot-top">
            <div className="col">Sign up to our Newsletter for 10% off</div>
            <div className="col">
              Follow us for news, offers and competitions!
              <div className="img">
                <img src={facebook} alt="" />
                <img src={twitter} alt="" />
                <img src={instagram} alt="" />
                <img src={pinterest} alt="" />
                <img src={youtube} alt="" />
              </div>
            </div>
          </div>
          <div className="foot-center">
            <div className="col"></div>
            <div className="col">
              <li onClick={() => this.routePage("about")}>About us</li>
              <li onClick={() => this.routePage("contact")}>Contact us</li>
              <li onClick={() => this.routePage("delivery")}>Delivery</li>
              <li onClick={() => this.routePage("return")}>Returns</li>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FooterBar;
