import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "../style/home.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import { productList } from "../apis/GetProduct";
import ShoppingCart from "../icon/ShoppingCart";
import tastydaily from "../image/tastydaily.svg";
import tastydaily2 from "../image/tastydaily2.svg";
import tastydaily3 from "../image/tastydaily3.svg";
import tastydaily4 from "../image/tastydaily4.svg";
import sec1 from "../image/sec1.webp";
import sec3 from "../image/sec3.webp";
import sec4 from "../image/sec4.png";
import img1 from "../image/img-row1.png";
import img2 from "../image/img-row2.png";
import img3 from "../image/img-row3.png";
import img4 from "../image/img-row4.png";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.routePage = this.routePage.bind(this);
    this.routeProductDetail = this.routeProductDetail.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "about":
        history.push("/About");
        break;
      case "pricing":
        history.push("/Pricing");
        break;
      case "contact":
        history.push("/Contact");
        break;
      default:
        break;
    }
  }


  routeProductDetail(id) {
    let { history } = this.props;
    history.push(`/ProductDetail/${id}`);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="home-page">
          <div className="sec1">
            <img src={sec1} alt="" />
          </div>
          <div className="sec2">
            <div className="title">Shop For Your PeT</div>
            <div className="row">
              {productList.map((item, index) => {
                return (
                  <div
                    className="col"
                    key={index}
                    onClick={() => this.routeProductDetail(item.id)}
                  >
                    <div className="img-box">
                      <img src={item.image[0]} lt="" />
                    </div>
                    <div className="txt">
                      <div className="title">{item.title}</div>
                      <div className="title2">{item.title2}</div>
                      <div className="tool">
                        <div className="price">From ${item.price}</div>
                        <div className="btn">SHOP NOW</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="sec3">
            <img src={sec3} alt="" />
            <div className="text">
              Dreamy <br /> days
            </div>
          </div>
          <div className="sec4">
            <div className="sec4-col">
              <h1 className="h1">We ask. We invent.</h1>
              <p className="p">
                We’re Omlet and since 2004 we’ve been rethinking every aspect of
                keeping and caring for pets. Inspired by the magic when humans
                and animals connect, we watch, learn and ask so we can invent.
                Our products will make you wonder: why hasn't it always been
                done this way?
              </p>
            </div>
            <div className="sec4-col">
              <img src={sec4} alt="" />
            </div>
          </div>
          <div className="sec5">
            <div className="col">
              DISCOVER <br /> WHAT <br /> MAKES <br /> US <br />
              DIFFERENT
            </div>
            <div className="col">
              <img src={img1} alt="" />
              <p>
                Bringing you & <br />
                your pets closer
              </p>
            </div>
            <div className="col">
              <img src={img2} alt="" />
              <p>
                Free returns <br /> for 180-days
              </p>
            </div>
            <div className="col">
              <img src={img3} alt="" />
              <p>
                Free shipping on <br /> orders over $100
              </p>
            </div>
            <div className="col">
              <img src={img4} alt="" />
              <p>Caill or email us</p>
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Home;
