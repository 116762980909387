// 1
import col1 from "../image/col1.webp";
import col1_1 from "../image/col1-1.webp";
import col1_2 from "../image/col1-2.webp";
import col1_3 from "../image/col1-3.webp";
import col1_4 from "../image/col1-4.webp";
import col1_5 from "../image/col1-5.webp";

// 2
import col2 from "../image/col2.webp";
import col2_1 from "../image/col2-1.webp";
import col2_2 from "../image/col2-2.webp";
import col2_3 from "../image/col2-3.webp";
import col2_4 from "../image/col2-4.webp";
import col2_5 from "../image/col2-5.webp";

// 3
import col3 from "../image/col3.webp";
import col3_1 from "../image/col3-1.webp";
import col3_2 from "../image/col3-2.webp";
import col3_3 from "../image/col3-3.webp";
import col3_4 from "../image/col3-4.webp";
import col3_5 from "../image/col3-5.webp";

// 4
import col4 from "../image/col4.webp";
import col4_1 from "../image/col4-1.webp";
import col4_2 from "../image/col4-2.webp";
import col4_3 from "../image/col4-3.webp";
import col4_4 from "../image/col4-4.webp";
import col4_5 from "../image/col4-5.webp";

// 5
import col5 from "../image/col5.webp";
import col5_1 from "../image/col5-1.webp";
import col5_2 from "../image/col5-2.webp";
import col5_3 from "../image/col5-3.webp";
import col5_4 from "../image/col5-4.webp";
import col5_5 from "../image/col5-5.webp";

// 6
import col6 from "../image/col6.webp";
import col6_1 from "../image/col6-1.webp";
import col6_2 from "../image/col6-2.webp";
import col6_3 from "../image/col6-3.webp";
import col6_4 from "../image/col6-4.webp";

export const productList = [
  {
    id: 1,
    title: "eglu pro",
    title2: "Turn your home into a homestead with our biggest ever Eglu.",
    description: [
      {
        img: col1_1,
        imgText: " A slide out nest box tray, for our easiest clean ever. ",
        title: "Sparkling clean in seconds",
        description: [
          "Does a slide out nest box tray sound easy like Sunday morning? With the Eglu Pro, cleaning just got even easier.",
          "Smooth surfaces that wipe clean",
          "Two slide-out droppings trays",
          "A seriously fast 5 min jet wash",
          "No wood grain for mites to hide in",
        ],
      },
      {
        img: col1_2,
        imgText: " Ribbed surface gives an extra firm grip. ",
        title: "A ladder to love",
        description: [
          "You asked, so we invented. Our designers developed, tested and refined 146 prototypes. Introducing the Eglu Pro’s patent pending ladder:",
          "Unique gridded step design",
          "Reduced angle for a confident climb",
          "Optimized rung frequency",
          "Ergonomic, anti slip ribs ",
          "Easy clean and anti slide surface texture",
          "New light gray color for increased visibility",
          "Overengineered? We just call it Thursday.",
        ],
      },
      {
        img: col1_3,
        imgText: " Weatherproof - no rotting or rusting. ",
        title: "Sustainable design",
        description: [
          "Long live Eglu coops - and they really do live a long, long time. Unlike fall-apart, ammonia-absorbing wood.",
          "Zero maintenance ",
          "All parts replaceable ",
          "The sustainable choice, designed to last for life",
          "10 year worry free warranty",
        ],
      },
      {
        img: col1_4,
        imgText: " Anti tunnel skirting stops predators digging in. ",
        title: "Fort Knox for your flocks",
        description: [
          "Our predator proof chicken coop design has been halting hawks, repelling raccoons, confounding coyotes and foiling foxes for 20 years. The Eglu Pro is the gold standard in chicken safety.",
          "Heavy duty, raccoon proof steel weld mesh run",
          "Unique anti-tunnel skirt prevents digging",
          "Two-step locks stops raccoon’s pesky paws",
          "World renowned Eglu toughness",
        ],
      },
      {
        img: col1_5,
        imgText: " Designed on the principles of cross flow ventilation. ",
        title: "California keepers, kick back",
        description: [
          "Your chickens are super safe in the Eglu Pro. Hawks, squirrels, rats or skunks - it’s a slam dunk. This coop makes light work of menace from above and all around.  ",
          "High density steel-weld mesh stops paws and claws",
          "World renowned Eglu strength and durability",
          "Designed to withstand the weight of a bear",
          "Two step raccoon proof locks",
          "Anti tunnel skirt stops predators digging in",
        ],
      },
    ],
    quantity: 1,
    image: [col1],
    sku: [
      { title: "Run length", value: "None" },
      { title: "Door", value: "Smart Autodoor - Green" },
      { title: "Accessories", value: "Coop Light" },
    ],
    price: "1799.00",
    total: "1799.00",
  },
  {
    id: 2,
    title: "smart autodoor",
    title2: "Use our app to connect with your flock, wherever you are.",
    description: [
      {
        img: col2_1,
        imgText: "  Check on your Smart Autodoor status in real time. ",
        title: "CONNECTED, WHEREVER YOU ARE",
        description: [
          "Our rigorously tested, ingenious design gives you 24/7 coop control from your phone, so you can check on the status of the chicken coop door and open and close it from anywhere.",
          "It’s also compatible with Alexa and Google Home, so you can check on your chickens even without your phone to hand.",
          "Just download the Omlet app for Android or Apple.",
        ],
      },
      {
        img: col2_2,
        imgText:
          " Set your Smart Autodoor open and close times on your phone. ",
        title: "dream schedule",
        description: [
          "Chickens thrive when they start their day at dawn and are in bed by dusk. Our Smart Autodoor lets you set their schedule, without disrupting yours.",
          "Choose from light responsive settings or set by the clock",
          "Easy to program with the Omlet app",
          "Sensors ignore other lights and torches",
        ],
      },
      {
        img: col2_3,
        imgText: " Attach to any wooden or DIY coop in minutes. ",
        title: "Make any coop smart",
        description: [
          "Not upgraded to an Eglu Cube or an Eglu Pro yet? Our Wi-Fi enabled Smart Autodoor is compatible with all chicken coops and comes with an easy attachment kit.",
          "Install directly to the Eglu Cube or Eglu Pro chicken coops",
          "Easily screws onto wooden coops ",
          "Attach to the Walk In Run or any type of chicken wire",
          "Can be fitted to open to the left or right",
        ],
      },
      {
        img: col2_4,
        imgText: "Choose from mains or battery power.",
        title: "Super power",
        description: [
          "The control panel can be attached to your chicken run or chicken coop, and connects to mains power with the 6ft cable provided. Alternatively, 4x AA batteries will power the Smart Autodoor for up to 3 months.",
        ],
      },
      {
        img: col2_5,
        imgText: "Choose from mains or battery power.",
        title: "Doors Closing",
        description: [
          "Sophisticated sensors protect hens that dawdle in the doorway. Once the path is clear, you’ll get a notification on your phone that the door is closed.",
        ],
      },
    ],
    quantity: 1,
    image: [col2],
    sku: [
      { title: "Color", value: "Green" },
      { title: "Coop Light", value: "None" },
      { title: "Attachment kits", value: "None" },
    ],
    price: "179.00",
    total: "179.00",
  },
  {
    id: 3,
    title: "Hamster Cage",
    title2: "Secure, enriching and easy clean hamster cage.",
    description: [
      {
        img: col3_1,
        imgText: "Spacious playpen makes out of cage time easy.",
        title: "POP UP & PLAY",
        description: [
          "Expand your small pet's horizons with our pop up Playpen - the perfect addition to any hamster or gerbil cage.",
          "Folds out and away in seconds",
          "Available in two sizes; large size lets kids join their pet inside",
          "Comes with wipeable floor protector ",
          "Free storage bag",
        ],
      },
      {
        img: col3_2,
        imgText: "Spacious playpen makes out of cage time easy.",
        title: "Dream clean",
        description: [
          "Whether it's a daily refresh or the full bedding change, Omlet's Hamster Cage is a dream to clean.",
          "Lift off top",
          "Spot clean in seconds ",
          "Quick wipe, smooth curved tray",
        ],
      },
      {
        img: col3_3,
        imgText: "We ask, so we can invent better ways to care for pets.",
        title: "omlet in the Workshop",
        description: [
          "With 10,000 hours of design time, we’ve engineered every detail of our Hamster Cage to deliver the highest quality finish and function.",
          "From ball bearings to make the wheel spin silently, to the concept of a complete system that integrates with your living space as a piece of furniture, we’re committed to going beyond the expected and inventing the remarkable.",
        ],
      },
      {
        img: col3_4,
        imgText: " Get closer with a clear view in and out. ",
        title: "it goes deep",
        description: [
          "Can hamsters dig deep without sending bedding flying? Our answer: yes, with a bedding fender.  ",
          "Eliminates bedding spill",
          "More burrow depth, supports hamster’s wellbeing ",
          "Creates a closer connection with your pet",
        ],
      },
      {
        img: col3_5,
        imgText:
          " Silent, high quality wheel suitable for Syrian and dwarf hamsters. ",
        title: "Small PET FRIENDLY",
        description: [
          "The Omlet Hamster Cage is designed with enrichment in mind, with expertly engineered extras to help you create an environment that meets their needs.",
          "Continuous floorspace, for little legs to explore",
          "Extra large silent wheel for mini marathons",
          "Comes with 2 compostable hides",
        ],
      },
    ],
    quantity: 1,
    image: [col3],
    sku: [
      { title: "Cage", value: "None" },
      { title: "Platforms & Ramps", value: "None" },
      { title: "Playpen ", value: "None" },
    ],
    price: "199.00",
    total: "199.00",
  },
  {
    id: 4,
    title: "switch",
    title2: "A scratcher that activates your cat’s apex predator instincts.",
    description: [
      {
        img: col4_1,
        imgText: "Engineered by us, tested by cats.",
        title: "BUST CAT BOREDOM",
        description: [
          "Cats don’t always play ball. We tested the Switch with lots of cats, lots of times, to see what makes them tick. And we found that, yep, cats are contrary. So we embraced that.",
        ],
      },
      {
        img: col4_2,
        imgText: "Light up their scratcher from the comfort of your couch.",
        title: "CONNECT IN COLOR",
        description: [
          "We created a program of over 1200 variations of light and color you can tailor to suit your cat, then switch up to keep the entertainment unpredictable.  ",
          "12 light programs",
          "7 color settings",
          "3 brightness settings",
          "7 speed settings",
        ],
      },
      {
        img: col4_3,
        imgText: "Switch on their natural instincts.",
        title: "SCRATCHING THE SCIENCE",
        description: [
          "In nature, cats scratch to keep their claws healthy and stretch their muscles, releasing a rush of relaxing endorphins. This is known as stropping, and the Switch is made for it.",
          "With vertical grooves for a long, fluid scratch and full body stretch, it’s a brain training, full body workout that will leave your cat relaxed and loose limbed. ",
        ],
      },
      {
        img: col4_4,
        imgText: "Keeps indoor cats entertained and active.",
        title: "COUCH CAT?",
        description: [
          "The Switch is also a neat solution to the challenge of keeping indoor cats active. Evening in? Switch on and watch them go wild. Off to work? Keep them as busy as you’ll be, with LED prey to stalk all day.",
        ],
      },
      {
        img: col4_5,
        imgText: "20% taller than the average scratcher.",
        title: "ANOTHER DIMENSION",
        description: [
          "At 33” the Switch is 50% bigger than the average cat when fully extended, for a limitless scratching sesh they can lean right into. ",
        ],
      },
    ],
    quantity: 1,
    image: [col4],
    sku: [
      { title: "Color", value: "Coffee" },
      { title: "LED lights", value: "None" },
    ],
    price: "169.00",
    total: "169.00",
  },
  {
    id: 5,
    title: "eglu cube",
    title2: "Can a chicken coop be quick to clean?",
    description: [
      {
        img: col5_1,
        imgText: "20% taller than the average scratcher.",
        title: "easiest coop clean eveR",
        description: [
          "The secret’s out. Cleaning your coop can actually be easy. With the Eglu Cube there’s no bending down, no relentless scrubbing or nasty chemicals. Just the satisfying feeling of a job well done.",
          "A slide out droppings tray",
          "Smooth surfaces that wipe clean",
          "A seriously fast 5 min jet wash",
          "No wood grain for mites to hide in",
        ],
      },
      {
        img: col5_2,
        imgText: " Designed and tested anti-raccoon handles. ",
        title: "FORT KNOX FOR YOUR FLOCKs",
        description: [
          "For over 15 years, our chicken coops have safeguarded America’s backyard flocks with unmatched protection against predators like hawks, raccoons, coyotes, bobcats and bears.",
          "Heavy duty steel weld mesh run",
          "Unique anti-tunnel skirt prevents digging",
          "Two-step locks stops raccoon’s pesky paws",
          "World renowned Eglu toughness",
          "Overengineered? Your chickens won’t think so.",
        ],
      },
      {
        img: col5_3,
        imgText:
          " The optional handles make it easy for one person to move the coop. ",
        title: "Customize your coop",
        description: [
          "Make their home your own with countless ways to customize your coop:",
          "Create more space with run extensions",
          "Make it a chicken tractor with wheels and handles",
          "Combine with a walk in chicken run",
          "Add chicken fencing for free-ranging",
          "Entertain with perches and treat toys",
        ],
      },
      {
        img: col5_4,
        imgText: " Add a Smart Autodoor and set your flock’s schedule. ",
        title: "Automate your coop door",
        description: [
          "Ever wished your chickens could let themselves out? We designed our smart automatic chicken coop door to open your coop at dawn and close again at dusk. Reliable, safe and durable in all weather, it’s an ingenious way to make your coop better and life just a little bit simpler.",
        ],
      },
      {
        img: col5_5,
        imgText:
          " The twin-wall insulation keeps your hens warm in their coop. ",
        title: "WHATEVER THE WEATHER",
        description: [
          "The Eglu Cube is expertly designed to keep your hens comfortable in all kinds of weather.",
          "Optimized ventilation stops drafts and damp air in the coop",
          "Twin-wall insulation keeps it cool in summer, and warm in winter",
          "Solid panels don’t soak up moisture",
          "Easy to anchor or move before hurricanes and tornadoes",
        ],
      },
    ],
    quantity: 1,
    image: [col5],
    sku: [
      { title: "Color", value: "Leaf Green" },
      { title: "Run", value: "None" },
      { title: "Autodoor", value: "Manual Door" },
    ],
    price: "899.00",
    total: "899.00",
  },
  {
    id: 6,
    title: "Dog Beds",
    title2: "What if a dog bed could live forever?",
    description: [
      {
        img: col6_1,
        imgText:
          " The twin-wall insulation keeps your hens warm in their coop. ",
        title: "Memory foam mattress and a perfectly angled headrest.",
        description: [
          "Our high performance memory foam mattress is a nap game changer. Swaddled in a plump and pillowy bolster, we’ve designed it to support your dog’s head perfectly without ever falling flat.",
        ],
      },
      {
        img: col6_2,
        imgText:
          "Sustainable needn’t be a compromise, with soft, durable rPet and luxurious GOTS cord.",
        title: "New shades of sustainable",
        description: [
          "We use super strong, upholstery grade fabrics tested to be claw and tooth resistant.",
          "We've also made steps towards a more sustainable dog bed with our printed collections, which use rPET materials made from recycled bottles, and our luxurious GOTS cotton cord covers.",
        ],
      },
      {
        img: col6_3,
        imgText: " Machine wash and dry for clean sheets in a flash. ",
        title: "Quick wash ‘n’ dry as a bone",
        description: [
          "Both the removable cover and the bolster are machine-wash and dryer-safe. Stock up on spare covers so you can just zip off, swap and wash whenever you need.",
        ],
      },
      {
        img: col6_4,
        imgText: "Unique designs for serious style.",
        title: "A dog bed for the catwalk",
        description: [
          "Exclusive prints, designed in house, mean your Bolster can complement your cushions or liven up your lounge. Available in rich colors, bold patterns or subtle neutrals, your dog’s bed will be as much a part of your home as your pooch is part of the family.",
        ],
      },
    ],
    quantity: 1,
    image: [col6],
    sku: [
      { title: "Size", value: "Large" },
      { title: "Pattern", value: "Navy blue" },
    ],
    price: "359.00",
    total: "359.00",
  },
];

export const getProductDetailById = (id) => {
  for (let i = 0; i < productList.length; i++) {
    if (productList[i].id === id) {
      return productList[i];
    }
  }
};

export const getRandomElements = (id) => {
  const result = [];
  const arrayCopy = [...productList]; // 创建数组的副本，以避免修改原始数组

  for (let i = 0; i < 4; i++) {
    let randomIndex;

    // 确保随机索引不与给定的 id 相同
    do {
      randomIndex = Math.floor(Math.random() * arrayCopy.length);
    } while (productList[randomIndex].id === id);

    const element = arrayCopy.splice(randomIndex, 1)[0];
    result.push(element);
  }

  return result;
};
