import React, { Component } from "react";
import "../style/checkout.scss";
import ShopIcon from "../icon/ShopIcon";
import ReactFlagsSelect from "react-flags-select";
import ReactPhoneInput from "react-telephone-input/lib/withStyles";
import flagImg from "../image/flag.png";
import * as FormValidation from "../utils/FormValidation";
import Reload from "./common/Reload";

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartTotal: 0,
      country: "GG",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      zip: "",
      isEmail: false,
      isPhone: false,
      isFirstName: false,
      isLastName: false,
      isAddress: false,
      isCity: false,
      isCountry: false,
      isZip: false,
      tip: "",
      reloadingShow: false,
      cartList: [],
    };
    this.setSelected = this.setSelected.bind(this);
    this.routeCart = this.routeCart.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.blurInput = this.blurInput.bind(this);
    this.clikctoPay = this.clikctoPay.bind(this);
    this.toggleReloadingShow = this.toggleReloadingShow.bind(this);
  }

  componentDidMount() {
    let cartList = JSON.parse(localStorage.getItem("cart"));
    let cartTotal = 0;
    cartList.map((item) => {
      cartTotal += parseInt(item.total);
    });
    this.setState({ cartList, cartTotal: cartTotal.toFixed(2) });
  }

  setSelected(code) {
    this.setState({ country: code });
    console.log(code);
  }

  routeCart() {
    const { history } = this.props;
    history.push("/Cart");
  }

  inputChange(e, type) {
    let telephone;
    switch (type) {
      case "email":
        this.setState({ email: e.target.value, tip: "" });
        break;
      case "firstName":
        this.setState({ firstName: e.target.value, tip: "" });
        break;
      case "lastName":
        this.setState({ lastName: e.target.value, tip: "" });
        break;
      case "country":
        this.setState({ country: e.target.value, tip: "" });
        break;
      case "address":
        this.setState({ address: e.target.value, tip: "" });
        break;
      case "city":
        this.setState({ city: e.target.value, tip: "" });
        break;
      case "zip":
        this.setState({ zip: e.target.value, tip: "" });
        break;
      case "phone":
        telephone = e.replace("-", "").replace(" ", "");
        this.setState({ phone: telephone, tip: "" });
        break;

      default:
        break;
    }
  }

  blurInput(e, type) {
    let reg;
    switch (type) {
      case "email":
        if (!FormValidation.isValidEmail(e.target.value)) {
          this.setState({ isEmail: true });
        } else {
          this.setState({ isEmail: false });
        }
        break;
      case "firstName":
        if (e.target.value.trim() === "") {
          this.setState({ isFirstName: true });
        } else {
          this.setState({ isFirstName: false });
        }
        break;
      case "lastName":
        if (e.target.value.trim() === "") {
          this.setState({ isLastName: true });
        } else {
          this.setState({ isLastName: false });
        }
        break;
      case "country":
        if (e.target.value.trim() === "") {
          this.setState({ isCountry: true });
        } else {
          this.setState({ isCountry: false });
        }
        break;
      case "city":
        if (e.target.value.trim() === "") {
          this.setState({ isCity: true });
        } else {
          this.setState({ isCity: false });
        }
        break;
      case "address":
        if (e.target.value.trim() === "") {
          this.setState({ isAddress: true });
        } else {
          this.setState({ isAddress: false });
        }
        break;
      case "zip":
        reg = /^[a-zA-Z0-9-_]{4,30}$/;
        if (!reg.test(e.target.value)) {
          this.setState({ isZip: true });
        } else {
          this.setState({ isZip: false });
        }
        break;
      case "phone":
        if (FormValidation.isValidPhoneNumber(e)) {
          this.setState({ isPhone: false });
        } else {
          this.setState({ isPhone: true });
        }
        break;
      default:
        break;
    }
  }

  clikctoPay() {
    let {
      isAddress,
      isCity,
      isEmail,
      isFirstName,
      isLastName,
      isPhone,
      isZip,
      email,
      firstName,
      lastName,
      country,
      city,
      zip,
      phone,
      address,
    } = this.state;

    let check =
      isAddress ||
      isCity ||
      isEmail ||
      isFirstName ||
      isLastName ||
      isPhone ||
      isZip;

    let disabled = false;

    disabled =
      email.trim() === "" ||
      city.trim() === "" ||
      address.trim() === "" ||
      zip.trim() === "" ||
      phone.trim() === "" ||
      firstName.trim() === "" ||
      lastName.trim() === "";

    if (disabled) {
      return;
    }

    if (check) {
      if (isEmail) {
        this.setState({ tip: "Invalid email address" });
        return;
      }
      if (phone) {
        this.setState({ tip: "Invalid phone number" });
        return;
      }
    } else {
      this.setState({ tip: "" });
      console.log("pay----");
      this.toggleReloadingShow();
      setTimeout(() => {
        this.toggleReloadingShow();
      }, 5000);
    }
  }

  toggleReloadingShow() {
    this.setState({ reloadingShow: !this.state.reloadingShow });
  }

  render() {
    const {
      cartList,
      cartTotal,
      email,
      firstName,
      lastName,
      country,
      city,
      zip,
      phone,
      address,
      tip,
    } = this.state;

    let next = false;

    next =
      email.trim() === "" ||
      city.trim() === "" ||
      address.trim() === "" ||
      zip.trim() === "" ||
      phone.trim() === "" ||
      firstName.trim() === "" ||
      lastName.trim() === "";

    return (
      <React.Fragment>
        <Reload {...this.props} reloadingShow={this.state.reloadingShow} />
        <div className="checkout-page">
          <div className="head">
            <div className="head-content">
              <h2>Omlet</h2>
              <div className="logo">{/* <img src={logo} alt="" /> */}</div>
              <ShopIcon
                size={32}
                color="#3d812f"
                className="icon"
                onClick={this.routeCart}
              />
            </div>
          </div>
          <div className="check-body">
            <div className="left">
              <div className="form-box">
                <div className="title">Billing Information</div>
                <input
                  type="text"
                  placeholder="Email (for sending your order receipt)"
                  onChange={(e) => this.inputChange(e, "email")}
                  onBlur={(e) => this.blurInput(e, "email")}
                />
                {/* <div className="title">Delivery</div> */}
                <ReactFlagsSelect
                  selected={country}
                  onSelect={(code) => this.setSelected(code)}
                />
                <div className="row">
                  <div className="half">
                    <input
                      type="text"
                      placeholder="First name (optional)"
                      onChange={(e) => this.inputChange(e, "firstName")}
                      onBlur={(e) => this.blurInput(e, "firstName")}
                    />
                  </div>
                  <div className="half">
                    <input
                      type="text"
                      placeholder="Last name"
                      onChange={(e) => this.inputChange(e, "lastName")}
                      onBlur={(e) => this.blurInput(e, "lastName")}
                    />
                  </div>
                </div>
                <input
                  type="text"
                  placeholder="House Number & Street Name"
                  onChange={(e) => this.inputChange(e, "address")}
                  onBlur={(e) => this.blurInput(e, "address")}
                />
                <input
                  type="text"
                  placeholder="City"
                  onChange={(e) => this.inputChange(e, "city")}
                  onBlur={(e) => this.blurInput(e, "city")}
                />
                <input
                  type="text"
                  placeholder="Postal code"
                  onChange={(e) => this.inputChange(e, "zip")}
                  onBlur={(e) => this.blurInput(e, "zip")}
                />
                <ReactPhoneInput
                  defaultCountry="us"
                  value={""}
                  flagsImagePath={flagImg}
                  onChange={(e) => this.inputChange(e, "phone")}
                  onBlur={(e) => this.blurInput(e, "phone")}
                />
                <div className="tip">{tip}</div>
                <div
                  className={`btn ${next ? "disable" : ""}`}
                  onClick={this.clikctoPay}
                >
                  CLCIK TO PAY
                </div>
              </div>
            </div>
            <div className="right">
              <div className="info-box">
                {cartList.map((item, index) => {
                  return (
                    <div className="tab-li" key={index}>
                      <div className="l">
                        <div className="img-box">
                          <img src={item.image[0]} alt="" />
                          <div className="num">{item.quantity}</div>
                        </div>
                        <div className="name">{item.title}</div>
                      </div>
                      <div className="total">${item.total}</div>
                    </div>
                  );
                })}
                <div className="subtotal">
                  Subtotal
                  <div className="price">${cartTotal}</div>
                </div>
                <div className="shipping">
                  Shipping
                  <div className="price">Free Ship</div>
                </div>
                <div className="total-row">
                  Total
                  <div className="price">${cartTotal}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Checkout;
