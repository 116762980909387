import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/about.scss";
import aboutImg from "../image/about.webp";

class About extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="about-page">
          <div className="title-head">About</div>
          <div className="about-contain">
            <div className="about-tent">
              <div className="title">can a chicken be a pet?</div>
              <p>
                That was the very first question that our four founders asked
                themselves in the final year of their product design course at
                the Royal College of Art in London. They left university in the
                summer of 2003 with the very first Eglu prototype, a coop design
                that challenged the norm and a dream of pet chickens in every
                home.
              </p>
              <div className="left-img">
                <div className="col">
                  <div className="title">behind the designs</div>
                  <div className="desc">
                    For 20 years, this inquisitive approach to design has
                    created remarkable products that bring people and pets
                    closer together. Our questions make dreamers of engineers,
                    watching and learning from our animals' behavior, we design
                    solutions that make you ask - why hasn’t it always been done
                    this way?
                  </div>
                  <div className="desc">
                    Scroll through the photos to see our journey so far.
                  </div>
                </div>
                <div className="col">
                  <div className="img-content">
                    <img src={aboutImg} alt="" />
                    <div className="text">
                      {" "}
                      Presenting our first prototype at the RCA Graduation Show
                      2003.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default About;
