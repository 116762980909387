import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import React, { Component } from "react";
import "../style/contact.scss";
import contactImg from "../image/contact.webp";

class ContacUs extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="contact-page">
          <div className="contact-content">
            <div className="head-contact">
              <h3>CONTACT US</h3>
              <p>PHONE: 646-434-1104</p>
              <p>EMAIL: info@omlet.us</p>
              <p>
                We're here to help: <br />
                Monday - Friday from 9.30am - 2.00pm ET
              </p>
              <p>
                Please contact us at info@omlet.us outside of the phone line
              </p>
              <p>
                operating hours and our agents will assist you with online
                support.
              </p>
            </div>
            <div className="contact-row">
              <div className="left">
                <div className="title">your question</div>
                <p>
                  Email us or complete the form and we'll get back to you as
                  quickly as possible, or call us if your query is urgent.
                </p>
                <div className="row">
                  <input type="text" placeholder="Your Name" />
                </div>
                <div className="row">
                  <input type="text" placeholder="Your Email" />
                </div>
                <div className="row">
                  <input type="text" placeholder="Your Phone Number" />
                </div>
                <div className="row">
                  <textarea placeholder="Your question"></textarea>
                </div>
                <div className="row">
                  <div className="btn">Submit</div>
                </div>
              </div>
              <div className="right">
                <img src={contactImg} alt="" />
              </div>
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default ContacUs;
